import * as React from 'react'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import { StaticImage, getImage, GatsbyImage } from 'gatsby-plugin-image'
import { Link, graphql, useStaticQuery } from 'gatsby'
import HaveQuestions from '../components/repairs/HaveQuestions'
import { processGatsbyImageDataMock } from '../common/gatsbyImageData'

const CTABoxesDL = () => {
  const { catalogImg } = useStaticQuery(graphql`
    query catalogCovers {
      catalogImg: strapiCatalog {
        cover {
          gatsbyImageDataMock
        }
      }
    }
  `)
  processGatsbyImageDataMock(catalogImg.cover)

  return (
    <Layout canonical="/register-browse-learn/">
      <Seo
        title="Register For Our Printed Catalog | Gray & Sons Jewelers"
        description="Stay informed with the latest collections from Gray and Sons by registering for their 76-page monthly printed catalog. Discover new arrivals and luxury jewelry conveniently delivered to your door."
        canonical="/register-browse-learn/"
      />
      <div className="w-full gap-10 flex flex-col lg:gap-0">
        <Link
          to="/catalog-request/"
          className="group overflow-hidden w-full flex flex-col lg:flex-row"
        >
          <div className="flex aspect-video justify-center bg-[#d8eaf0] p-4 items-center lg:w-1/2">
            <GatsbyImage
              image={getImage(catalogImg.cover)}
              className=" aspect-[85/110] max-w-[20rem] md:max-w-max max-h-[50vh] h-full duration-200 hover:rotate-6 hover:scale-110"
            />
          </div>
          <div className="w-full flex flex-col justify-center items-center lg:items-start lg:w-1/2 px-[8.4%]">
            <h1 className="text-2xl group-hover:tracking-wider ease-out 2xl py-5 font-bold uppercase duration-300  group-hover:text-red-700 w-10/12">
              Register{' '}
              <span className="text-lg font-normal tracking-normal text-black">
                <br />
                For Our Printed Catalog
              </span>
            </h1>

            <p className=" w-10/12 max-w-[800px] leading-loose">
              Registering to receive the 76-page monthly printed catalog from Gray and Sons is a
              convenient and valuable way for customers to stay informed and connected with the
              latest collections and new arrivals at this renowned luxury jeweler.
            </p>
          </div>
        </Link>
        <Link
          to="/digital-catalog/"
          className="group overflow-hidden w-full flex flex-col lg:flex-row"
        >
          <div className="order-2 lg:order-1 w-full flex flex-col justify-center items-center lg:items-start lg:w-1/2 px-[8.4%]">
            <h3 className="text-2xl group-hover:tracking-wider ease-out 2xl py-5 font-bold uppercase duration-300  group-hover:text-red-700 w-10/12">
              Browse{' '}
              <span className="text-lg font-normal tracking-normal text-black">
                <br />
                Our Digital Catalog
              </span>
            </h3>

            <p className=" w-10/12 max-w-[800px] leading-loose">
              Registering to receive the 76-page monthly printed catalog from Gray and Sons is a
              convenient and valuable way for customers to stay informed and connected with the
              latest collections and new arrivals at this renowned luxury jeweler.
            </p>
          </div>
          <div className="order-1 lg:order-2 flex aspect-video justify-center bg-[#eee9d5] p-4 items-center lg:w-1/2">
            <StaticImage
              alt={'Digital Catalog'}
              src="../images/GnS-Home-Page-BROWSE-button.png"
              loading="lazy"
              className="aspect-square max-w-[20rem] md:max-w-max max-h-[50vh] h-full duration-200 hover:rotate-6 hover:scale-110"
            />
          </div>
        </Link>
        <Link to="/blog/" className="group overflow-hidden w-full flex flex-col lg:flex-row">
          <div className="flex aspect-video justify-center bg-[#d8cfc6] p-4 items-center lg:w-1/2">
            <StaticImage
              alt={'Digital Catalog'}
              src="../images/GnS-Home-Page-LEARN-button.png"
              loading="lazy"
              className="aspect-square max-w-[20rem] md:max-w-max max-h-[50vh] h-full duration-200 hover:rotate-6 hover:scale-110"
            />
          </div>
          <div className="w-full flex flex-col justify-center items-center lg:items-start lg:w-1/2 px-[8.4%]">
            <h3 className="text-2xl group-hover:tracking-wider ease-out 2xl py-5 font-bold uppercase duration-300  group-hover:text-red-700 w-10/12">
              Learn{' '}
              <span className="text-lg font-normal tracking-normal text-black">
                <br />
                with Our Blogs
              </span>
            </h3>

            <p className=" w-10/12 max-w-[800px] leading-loose">
              Registering to receive the 76-page monthly printed catalog from Gray and Sons is a
              convenient and valuable way for customers to stay informed and connected with the
              latest collections and new arrivals at this renowned luxury jeweler.
            </p>
          </div>
        </Link>
      </div>
      <div className="w-10/12 mx-auto max-w-[1366px] my-20">
        <HaveQuestions />
      </div>
    </Layout>
  )
}

export default CTABoxesDL
